<template>
  <div class="autocomplete">
    <!-- hidden input for return 'MemberID' to v-model -->
    <input 
      type="hidden"
      v-model="memberId"
    >

    <!-- input for search Member Name -->
    <input
      type="text" 
      ref="searchInput"
      v-model="inputValue"
      @input="onInputEvent"
      @keydown="onKeydownEvent"
      placeholder="ชื่อสมาชิก..."
      autocomplete="off"
    >
    
    <!-- Suggest list -->
    <div
      class="autocomplete--items"
      v-if="showSuggest" 
      v-click-outside="onClickOutside"
    >
      <!-- loop of Suggest item -->
      <div 
        v-for="(item, idx) in suggest" 
        :key="idx" 
        :class="{ 
          'autocomplete--active': idx === focus 
        }"
        @click="onClickItem(item)"
      >
        {{ item.fullname }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * SuggestMemberNameInput
 *  @description
 *    Auto seggest by member name (include company name)
 *  
 *  @value 
 *    {value} - input value
 *    {showSuggest} - value for show/hide suggest list
 *    {suggest} - array of suggest list
 *    {focus} - current selected item index
 *  
 * 
 *  @method handleInput() 
 *    handle input for emit to parent by v-model
 * 
 *  @method onInputEvent() 
 *    active when key in input
 * 
 *  @method onKeydownEvent() 
 *    detect 'down', 'up', 'enter' key for select suggest item by keyboard
 * 
 *  @method onClickItem() 
 *    active when click
 * 
 *  @method onClickOutside()
 *    detect click outside this component
 *    if it's true, close all suggestion
 * 
 *  @method closeSuggest()
 *    all suggestion
 * 
 */

import Vue from "vue";
import {apiRequest} from '@/utils/axios/axiosInstance.js';


/**
 * detect click outside
 */
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  prop: [
    'value',
    'type'
  ],
  
  data() {
    return {
      inputValue: '',
      showSuggest: false,
      suggest: [],
      focus: -1,

      data: [],

      memberId: '',
    }
  },

  /**
   * load Member name form
   */
  created() {
    this.getData();
  },

  mounted() {
    
  },

  methods: {
    /**
     * get member data
     */
    getData() {
      let params = { 
        limit: 10000,
        constructor: 1,
      };
      
      apiRequest
        .get( '/member', { params: params})
        .then( (res) => {
          if(res.data.row > 0) {
            this.data = res.data.result;
          }
        })
      // this.searchInpuFocus();
    },

    /**
     * focus search input
     */
    searchInpuFocus() {
      this.$refs.searchInput.focus();
    },

    /**
     * emit value to parent v-model
     */
    updateMemberId (memberId) {//value
      this.memberId = memberId;
      this.$emit('input', memberId);
    },
      
    /**
     * Handle on input event 
     *  find match name by input value when enter any key
     */
    onInputEvent: function() {
      // filter match inputValue
      this.suggest = this.data.filter((itemData) => {
        return itemData.fullname.substr(0, this.inputValue.length).toUpperCase() == this.inputValue.toUpperCase()
      });

      // clear old memvberId
      this.updateMemberId ('');

      // if input value has matching data, display suggest list
      this.focus = -1;
      this.showSuggest = this.suggest.length > 0;
    },

    /**
     * Keydown event handled
     * detect 'down', 'up', 'enter' key for select suggest item by keyboard
     */
    onKeydownEvent:function(e) {
      // detect 'showSuggest' is true
      if (!this.showSuggest) return;

      let key = e.key;

      if (key == 'ArrowDown' && this.focus < this.suggest.length - 1) {
        this.focus++;
      } else if (key == 'ArrowUp' && this.focus > 0) {
        this.focus--;
      } else if (key == 'Enter') {
        // prevent enter to submit form
        e.preventDefault(); 

        // if no focus some item
        if(this.focus === -1) {
          alert('กรุณาเลือกหนึ่งรายการก่อนกด Enter');
          return;
        }

        // set item data to search input
        this.inputValue = this.suggest[this.focus].fullname;
        this.updateMemberId( this.suggest[this.focus].MID );

        // close suggest
        this.closeSuggest();
      }
    },

    /**
     * Click suggest item
     */
    onClickItem(item) {
      this.inputValue = item.fullname;
      this.updateMemberId( this.item.MID );
      this.closeSuggest();
    },


    /**
     * Click outside components
     */
    onClickOutside() {
      this.closeSuggest();
    },

    /**
     * Close suggest list
     */
    closeSuggest() {
      this.focus = -1;
      this.showSuggest = false
    },
  },
}
</script>


<style scoped>
.autocomplete {
  position: relative;
}
.autocomplete--items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete--items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border-bottom: 1px solid #d4d4d4; 
}
.autocomplete--items div:hover {
  background-color: #e9e9e9; 
}
.autocomplete--active {
  background-color: DodgerBlue !important; 
  color: #ffffff; 
}
</style>